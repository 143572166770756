export const navbar = [
  {
    title: 'Home',
    path: '/',
  },
  {
    title: 'Categories',
    path: '/categories',
  },
  /*{
    title: 'Projects',
    path: '/projects',
  }, */
  {
    title: 'Blog',
    path: '/posts',
  },
];
